import { ChangeDetectorRef, Directive, Input, OnChanges } from '@angular/core';
import { Constants } from '../constants';

@Directive({
  selector: '[appImageLoader]',
  host: {
    '[attr.src]': '_processedSrc'
  }
})
export class ImageLoaderDirective implements OnChanges {
  @Input({ required: true }) src = '';
  @Input() appImageLoader = Constants.staticImages.loaders.imageLoader;
  @Input() appImagePlaceholder = Constants.staticImages.images.profileImgPlaceholder;
  _processedSrc!: string;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  ngOnChanges(): void {
    this._processedSrc = this.appImageLoader;
    const image = new Image();
    image.onerror = () => {
      this._processedSrc = this.appImagePlaceholder;
      this.cdr.detectChanges();
    };
    image.onload = () => {
      this._processedSrc = this.src;
      this.cdr.detectChanges();
    };
    image.src = this.src;
  }
}
