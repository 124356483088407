import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTER_PATHS } from '../constants';
import { AuthService } from 'src/app/auth/services';

@Injectable({
  providedIn: 'root'
})
export class NoAuthGuard {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  canMatch(): boolean {
    const isLoggedIn = this.authService.isLoggedIn;
    const isResetPasswordPage = this.router
      .getCurrentNavigation()
      ?.initialUrl.root.children['primary'].segments.some((segment) => segment.path.includes('forgot-password'));

    if (isLoggedIn && isResetPasswordPage) {
      return true;
    }

    if (isLoggedIn) {
      this.router.navigate([ROUTER_PATHS.dashboard.root]);
      return false;
    }

    return true;
  }
}
