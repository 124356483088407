import { Directive, ElementRef, Input, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appLoader]'
})
export class ShowLoaderDirective {
  @Input() set appLoader(loading: boolean) {
    if (loading) {
      this.showLoader();
    } else {
      this.hideLoader();
    }
  }

  private loaderElement: HTMLElement;

  constructor(
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.loaderElement = this.renderer.createElement('span');
    this.renderer.addClass(this.loaderElement, 'loader');
    this.renderer.setStyle(this.loaderElement, 'margin-left', '8px');
  }

  private showLoader(): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', true);
    this.renderer.appendChild(this.el.nativeElement, this.loaderElement);
  }

  private hideLoader(): void {
    this.renderer.setProperty(this.el.nativeElement, 'disabled', false);
    if (this.el.nativeElement.contains(this.loaderElement)) {
      this.renderer.removeChild(this.el.nativeElement, this.loaderElement);
    }
  }
}
