import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ImageCompressionService {
  compressImage(options: {
    file: File;
    compressAfterFileSize?: number;
    quality?: number;
    maxWidth?: number;
    maxHeight?: number;
  }): Observable<File> {
    if (!options.quality) {
      options.quality = 0.7;
    }
    if (!options.compressAfterFileSize) {
      options.compressAfterFileSize = environment.compressAfterFileSize;
    }
    return new Observable((observer: Observer<File>) => {
      const type = 'image/' + options.file.name.split('.').pop();
      const reader = new FileReader();
      reader.readAsDataURL(options.file);

      reader.onload = (event) => {
        const img = new Image();
        img.src = event.target?.result as string;

        img.onload = () => {
          const canvas = document.createElement('canvas');
          let width = img.width;
          let height = img.height;
          options.maxWidth = options.maxWidth ?? width;
          options.maxHeight = options.maxHeight ?? width;

          if (width > options.maxWidth) {
            height *= options.maxWidth / width;
            width = options.maxWidth;
          }

          if (height > options.maxHeight) {
            width *= options.maxHeight / height;
            height = options.maxHeight;
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext('2d');
          ctx?.drawImage(img, 0, 0, width, height);

          const compressedDataUrl = canvas.toDataURL(type, options.quality);

          // Convert the data URL back to a Blob
          const byteString = atob(compressedDataUrl.split(',')[1]);
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          const compressedBlob = new Blob([ab], { type });

          const compressedFile = new File([compressedBlob], options.file.name, { type });

          observer.next(compressedFile);
          observer.complete();
        };
      };

      reader.onerror = (error) => {
        observer.error(error);
      };
    });
  }
}
