import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ROUTER_PATHS } from '../constants';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  constructor(private readonly router: Router) {}

  navigateToDashboard() {
    this.router.navigate([ROUTER_PATHS.root, ROUTER_PATHS.dashboard.root]);
  }

  navigateToLogin() {
    this.router.navigate([ROUTER_PATHS.auth.root, ROUTER_PATHS.auth.login]);
  }

  navigate(commands: string[], navigationExtras?: { [key: string]: any } | undefined): Promise<boolean> {
    return this.router.navigate(commands, navigationExtras);
  }
}
