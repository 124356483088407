import { Directive, ElementRef, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appItemHover]'
})
export class ItemHoverDirective {
  constructor(
    private renderer: Renderer2,
    hostElement: ElementRef
  ) {
    renderer.addClass(hostElement.nativeElement, 'hover-item');
  }
}
