<div class="header-container" [ngClass]="'justify-content-between'">
  <div class="header-content">
    <div class="d-none d-md-block">
      <img [src]="constants.applicationLogoUrl" alt="Company Logo" class="logo" />
    </div>
    <div class="d-none d-md-flex align-items-md-end menu-items-container ms-2">
      <div *ngFor="let menuItem of menuItems">
        <div
          *ngIf="menuItem.isAccessible && !menuItem?.subMenuOptions?.length"
          [routerLink]="menuItem.routerLink"
          routerLinkActive="active-route"
          class="menu-item"
          appItemHover>
          <i [class]="menuItem.icon"></i>
          <span class="ms-1">{{ menuItem.title }}</span>
        </div>
        <div *ngIf="menuItem.isAccessible && menuItem?.subMenuOptions?.length" class="d-none d-md-block" appItemHover>
          <div
            class="menu-item"
            [ngClass]="{
              'active-route': currentActiveParentRoute === menuItem.id
            }">
            <i [class]="menuItem.icon"></i>
            <span class="ms-1">{{ menuItem.title }}</span>
            <i class="pi pi-chevron-down parent-route-chevron"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="me-2">
    <app-header-elements></app-header-elements>
  </div>
</div>
