<h2 mat-dialog-title>{{ data?.title ?? "Confirm Action" }}</h2>
<mat-dialog-content>
  <p>{{ data?.message ?? "Are you sure you want to proceed?" }}</p>
</mat-dialog-content>
<mat-dialog-actions [align]="'end'">
  <button mat-raised-button color="accent" class="mat-accent-btn back-btn" (click)="onCancel()">
    {{ data?.rejectBtnName ?? "No" }}
  </button>
  <button mat-raised-button color="warn" class="mat-warn-btn" (click)="onConfirm()">
    {{ data?.acceptBtnName ?? "Yes" }}
  </button>
</mat-dialog-actions>
