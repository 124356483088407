import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { HasPermissionDirective, ImageIconDirective, PasswordEyeDirective, ShowLoaderDirective } from '.';
import { DisableCopyPasteDirective } from './disable-copy-paste.directive';
import { ImageLoaderDirective } from './image-loader.directive';
import { ItemHoverDirective } from './item-hover.directive';
import { DragAndDropFileDirective } from './drag-and-drop-file.directive';

const DIRECTIVES = [
  PasswordEyeDirective,
  ImageIconDirective,
  ShowLoaderDirective,
  ImageLoaderDirective,
  ItemHoverDirective,
  DisableCopyPasteDirective,
  HasPermissionDirective,
  DragAndDropFileDirective
];

@NgModule({
  declarations: [...DIRECTIVES],
  imports: [CommonModule],
  exports: [...DIRECTIVES]
})
export class DirectivesModule {}
