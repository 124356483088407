import { Injectable } from '@angular/core';
import { Observable, Subject, map, of, take, takeUntil } from 'rxjs';
import { Instrument } from 'src/app/request-information/models';
import { InstrumentsService } from 'src/app/request-information/services';
import { API_URL } from '../constants/api-url.constants';
import { CBResponse } from '../models';
import { SchoolLocations } from 'src/app/pages/room-and-location-management/models';
import { LocationService } from 'src/app/pages/room-and-location-management/pages/location/services';
import { InstructorList } from 'src/app/schedule-introductory-lesson/models';
import { InstructorService } from 'src/app/schedule-introductory-lesson/services';
import { RevenueCategory } from 'src/app/pages/settings/pages/revenue-categories/models';
import { RevenueCategoriesService } from 'src/app/pages/settings/pages/revenue-categories/services';

@Injectable({ providedIn: 'root' })
export class CommonService {
  isApiCallInProgress$: Subject<boolean> = new Subject();
  instruments!: CBResponse<Instrument>;
  locations!: CBResponse<SchoolLocations>;
  instructors!: CBResponse<InstructorList>;
  revenueCategories!: CBResponse<RevenueCategory>;

  constructor(
    private instrumentsService: InstrumentsService,
    private readonly locationService: LocationService,
    private readonly instructorService: InstructorService,
    private readonly revenueCategoriesService: RevenueCategoriesService
  ) {}

  getInstruments(): Observable<CBResponse<Instrument>> {
    if (!this.instruments?.result?.items?.length) {
      return this.instrumentsService.getList<CBResponse<Instrument>>(API_URL.crud.getAll).pipe(
        take(1),
        map((res) => {
          this.instruments = res as CBResponse<Instrument>;
          return res;
        })
      );
    }
    return of(this.instruments);
  }

  getLocations(): Observable<CBResponse<SchoolLocations>> {
    if (!this.locations?.result?.items?.length) {
      return this.locationService.getList<CBResponse<SchoolLocations>>(API_URL.crud.getAll).pipe(
        take(1),
        map((res) => {
          this.locations = res as CBResponse<SchoolLocations>;
          return res;
        })
      );
    }
    return of(this.locations);
  }

  getInstructors(): Observable<CBResponse<InstructorList>> {
    if (!this.instructors?.result?.items?.length) {
      return this.instructorService.getList<CBResponse<InstructorList>>(`${API_URL.crud.getAll}?page=1`).pipe(
        take(1),
        map((res) => {
          this.instructors = res as CBResponse<InstructorList>;
          return res;
        })
      );
    }
    return of(this.instructors);
  }

  getRevenueCategories(): Observable<CBResponse<RevenueCategory>> {
    if (!this.revenueCategories?.result?.items?.length) {
      return this.revenueCategoriesService
        .getListWithFilters<CBResponse<RevenueCategory>>({}, `${API_URL.crud.getAll}`)
        .pipe(
          take(1),
          map((res) => {
            this.revenueCategories = res;
            return res;
          })
        );
    }
    return of(this.revenueCategories);
  }
}
