import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ControlType } from '../../constants/type.constants';

@Component({
  selector: 'app-error-messages',
  template: `
    <small class="text-danger" *ngFor="let errorMessage of listErrors(); let last = last">
      {{ last ? errorMessage : '' }}
    </small>
  `,
  styles: [
    `
      .text-danger {
        font-size: 12px;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.Default
})
export class ErrorHandlerComponent {
  errorMsgList: string[] = [];

  @Input()
  control!: ControlType;

  errorMessage: any = {
    required: () => `This field is required`,
    email: () => `Invalid email`,
    maxlength: (params: { requiredLength: number }) => `Maximum ${params?.requiredLength} characters are allowed`,
    minlength: (params: { requiredLength: number }) => `Minimum ${params?.requiredLength} characters are required`,
    min: (params: { min: number }) => `Should be greater than or equal to ${params?.min} `,
    max: (params: { max: number }) => `Should be smaller than or equal to ${params?.max} `,
    pattern: () => `Invalid format`,
    whitespace: () => `White spaces are not allowed`,
    misMatch: () => `Passwords don't match`,
    timeRangeInvalid: () => `End time should be greater than start time`
  };

  listErrors(): string[] {
    if (!this.control) {
      return [];
    }
    if (this.control?.errors) {
      this.errorMsgList = [];
      Object.keys(this.control?.errors).forEach((error) => {
        const errorKey = this.control?.errors ? this.control?.errors[error] : '';
        const errorMsg = errorKey && this.errorMessage[error] ? this.errorMessage[error](errorKey) : '';
        this.control?.touched || (this.control?.touched && this.control?.dirty) ? this.errorMsgList.push(errorMsg) : '';
      });
      return this.errorMsgList;
    } else {
      return [];
    }
  }
}
