import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { LoginResponse } from 'src/app/auth/models';
import { API_URL } from '../constants/api-url.constants';
import { LocalStorageService, StorageItem } from './local-storage.service';

@Injectable({ providedIn: 'root' })
export class RefreshTokenService {
  private refreshTokenTimeout!: NodeJS.Timeout;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly localStorageService: LocalStorageService
  ) {}

  startRefreshTokenTimer(expireTime: number) {
    // parse json object from base64 encoded jwt token
    const jwtToken = expireTime;

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), expireTime);
  }

  stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  refreshToken(): Observable<LoginResponse | undefined> {
    const refreshToken = this.localStorageService.getItem(StorageItem.RefreshToken);
    return this.httpClient
      .post<LoginResponse>(
        `${API_URL.account.tokeAuth}/${API_URL.account.refreshAccessToken}?refreshToken=${refreshToken}`,
        {}
      )
      .pipe(
        map((response) => {
          this.localStorageService.setItem(StorageItem.AuthToken, response.result.accessToken);
          this.startRefreshTokenTimer(response.result.expireInSeconds);
          return response;
        })
      );
  }

  storeAuthTokens(loginResponse: LoginResponse) {
    this.localStorageService.setItem(StorageItem.AuthToken, loginResponse.result.accessToken);
    this.localStorageService.setItem(StorageItem.RefreshToken, loginResponse.result.refreshToken);
  }
}
