import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
/**
 * This is to achieve abstraction,
 * If in future, we need to change toast service, we can change at this place.
 */

@Injectable({ providedIn: 'root' })
export class AppToasterService {
  toasterLife = 2000; // in milliseconds

  constructor(private readonly snackBar: MatSnackBar) {}

  success(message: string, cssClass: string[] = []): void {
    this.snackBar.open(message, 'x', {
      duration: this.toasterLife,
      panelClass: [...cssClass, 'success-snackbar-color', 'snackbar'],
      horizontalPosition: 'center'
    });
  }

  /** show error toast */
  error(message: string, cssClass: string[] = []): void {
    this.snackBar.open(message, 'x', {
      duration: this.toasterLife,
      panelClass: [...cssClass, 'error-snackbar-color', 'snackbar'],
      horizontalPosition: 'center'
    });
  }

  /** show info toast */
  info(message: string, cssClass: string[] = []): void {
    this.snackBar.open(message, 'x', {
      duration: this.toasterLife,
      panelClass: [...cssClass, 'info-snackbar-color', 'snackbar'],
      horizontalPosition: 'center'
    });
  }

  /** show warning toast */
  warning(message: string, cssClass: string[] = []): void {
    this.snackBar.open(message, 'x', {
      duration: this.toasterLife,
      panelClass: [...cssClass, 'warning-snackbar-color', 'snackbar'],
      horizontalPosition: 'center'
    });
  }
}
