import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { SharedComponentsModule } from './components/shared-components.module';
import { DirectivesModule } from './directives/directives.module';

const SHARED_MODULES = [SharedComponentsModule, DirectivesModule, FontAwesomeModule];

@NgModule({
  declarations: [],
  imports: [CommonModule, ...SHARED_MODULES],
  exports: [...SHARED_MODULES]
})
export class SharedModule {}
