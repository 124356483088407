import { Component } from '@angular/core';

@Component({
  selector: 'app-content-loader',
  template: ` <div class="content-loader">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
  </div>`
})
export class ContentLoaderComponent {}
