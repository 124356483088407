import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { LocalStorageService, StorageItem } from './local-storage.service';

export enum THEMES {
  LIGHT = 'LIGHT',
  DARK = 'DARK'
}

export enum GLOBAL_THEMES {
  BOOTSTRAP4_LIGHT_BLUE = 'bootstrap4-light-blue',
  BOOTSTRAP4_DARK_BLUE = 'bootstrap4-dark-blue'
}

export enum CHART_THEMES {
  VINTAGE = 'vintage',
  DARK = 'dark',
  MACARONS = 'macarons',
  INFOGRAPHIC = 'infographic',
  SHINE = 'shine',
  ROMA = 'roma'
}

@Injectable({
  providedIn: 'root'
})
export class ThemeService {
  idealTheme: THEMES = THEMES.LIGHT;

  readonly selectedTheme = new BehaviorSubject(this.getStoredTheme() || this.idealTheme);
  readonly selectedTheme$: Observable<THEMES> = this.selectedTheme.asObservable();

  readonly selectedChartTheme = new BehaviorSubject(this.getChartThemeByTheme(this.idealTheme));
  readonly selectedChartTheme$: Observable<CHART_THEMES> = this.selectedChartTheme.asObservable();

  constructor(
    @Inject(DOCUMENT) private readonly document: Document,
    private readonly localStorageService: LocalStorageService
  ) {}

  private getStoredTheme(): THEMES | null {
    return this.localStorageService.getItem(StorageItem.Theme) as THEMES | null;
  }

  changeTheme(theme: THEMES, saveToLocalStorage = true): void {
    this.selectedTheme.next(theme);

    if (theme === THEMES.LIGHT) {
      this.changeGlobalTheme(GLOBAL_THEMES.BOOTSTRAP4_LIGHT_BLUE);
      this.changeChartTheme(CHART_THEMES.INFOGRAPHIC);
    } else if (theme === THEMES.DARK) {
      this.changeGlobalTheme(GLOBAL_THEMES.BOOTSTRAP4_DARK_BLUE);
      this.changeChartTheme(CHART_THEMES.DARK);
    }

    if (saveToLocalStorage) {
      this.localStorageService.setItem(StorageItem.Theme, theme);
    }
  }

  private changeGlobalTheme(globalTheme: GLOBAL_THEMES): void {
    const themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;

    if (themeLink) {
      themeLink.href = `${globalTheme}.css`;
    }
  }

  private getChartThemeByTheme(theme: THEMES): CHART_THEMES {
    if (theme === THEMES.LIGHT) {
      return CHART_THEMES.INFOGRAPHIC;
    } else if (theme === THEMES.DARK) {
      return CHART_THEMES.DARK;
    } else {
      return CHART_THEMES.DARK;
    }
  }

  private changeChartTheme(chartTheme: CHART_THEMES): void {
    this.selectedChartTheme.next(chartTheme);
  }
}
