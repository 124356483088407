import { firstValueFrom } from 'rxjs';
import { LoginResponse } from 'src/app/auth/models';
import { EncryptionService } from './encryption.service';
import { LocalStorageService, StorageItem } from './local-storage.service';
import { RefreshTokenService } from './refresh-token.service';

export function refreshTokenInitializer(
  refreshTokenService: RefreshTokenService,
  encryptionService: EncryptionService
) {
  return async (): Promise<LoginResponse | null> => {
    const localStorageService = new LocalStorageService(encryptionService);
    if (!localStorageService.getItem(StorageItem.AuthToken)) {
      return Promise.resolve(null);
    }
    const refreshTokenResponse = await firstValueFrom(refreshTokenService.refreshToken()).catch((err) => {
      // do nothing here. it is added just to catch
    });
    return refreshTokenResponse || Promise.resolve(null);
  };
}
