import { ChangeDetectionStrategy, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { SplashScreenService } from '../../services/splash-screen.service';
import { BaseComponent } from '../base-component/base.component';

@Component({
  selector: 'app-splash-screen',
  templateUrl: './splash-screen.component.html',
  styleUrls: ['./splash-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SplashScreenComponent extends BaseComponent implements OnInit {
  @ViewChild('splashScreen', { static: true }) splashScreen!: ElementRef;

  constructor(private readonly splashScreenService: SplashScreenService) {
    super();
  }

  ngOnInit(): void {
    this.splashScreenService.init(this.splashScreen);
  }
}
