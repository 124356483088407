export interface CBResponse<T> {
  result: {
    totalCount: number;
    items: T[];
  };
}

export interface CBGetResponse<T> {
  result: T;
}
