import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Account } from 'src/app/auth/models/user.model';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TopnavComponent implements OnChanges {
  @Input() currentUser!: Account | null;

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['currentUser']?.currentValue) {
      this.currentUser = changes?.['currentUser']?.currentValue;
    }
  }
}
