import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { environment } from 'src/environments/environment';
import { Constants } from '../../constants/app.constants';
import { ImageCompressionService } from '../../services/compress-image.service';
import { ASPECT_RATIOS, AspectRatioOptions, IMAGE_COMPRESSION, ImageCropperModel } from './crop-image.model';

@Component({
  selector: 'app-crop-image',
  templateUrl: './crop-image.component.html',
  styleUrls: ['./crop-image.component.scss']
})
export class CropImageComponent implements OnInit {
  @Input({ required: true }) imageCropper!: ImageCropperModel;
  @Input({ required: false }) compressAfterFileSize: number = environment.compressAfterFileSize;
  @Output() croppedImage: EventEmitter<File> = new EventEmitter<File>();
  @Output() cancelCropping: EventEmitter<boolean> = new EventEmitter<boolean>();
  croppedImageUrl: SafeUrl = '';
  file!: File;
  aspectRatioOptions = [
    { label: AspectRatioOptions.Square, value: ASPECT_RATIOS.square },
    { label: AspectRatioOptions.FourThree, value: ASPECT_RATIOS.rectangle },
    { label: AspectRatioOptions.SixteenNine, value: ASPECT_RATIOS.wideRectangle }
  ];
  imageCompression = IMAGE_COMPRESSION;
  isLoading = true;
  imageLoader = Constants.staticImages.loaders.imageLoader;

  constructor(
    private readonly sanitizer: DomSanitizer,
    private readonly compressImageService: ImageCompressionService
  ) {}

  ngOnInit(): void {}

  imageCropped(event: ImageCroppedEvent): void {
    if (!event.objectUrl) {
      return;
    } else {
      this.croppedImageUrl = this.sanitizer.bypassSecurityTrustUrl(event.objectUrl ? event.objectUrl : '');
      if (event.blob) {
        this.file = new File([event.blob], this.imageCropper?.options.name, {
          type: event.blob.type,
          lastModified: new Date().getTime()
        });
      } else {
        this.croppedImage.emit(this.file);
      }
    }
  }

  compressCroppedImage(): void {
    this.compressImageService
      .compressImage({
        file: this.file,
        compressAfterFileSize: this.compressAfterFileSize,
        quality: this.imageCompression.quality,
        maxWidth: Number(this.imageCropper?.options.outputWidth),
        maxHeight: this.imageCropper?.options.outputHeight
      })
      .subscribe((compressedFile: File) => {
        this.croppedImage.emit(compressedFile);
        this.closeCropper();
      });
  }

  getAspectRatio(aspectRatio: number): string {
    const closestOption = this.aspectRatioOptions.reduce((prev, curr) => {
      const prevDiff = Math.abs(prev.value - aspectRatio);
      const currDiff = Math.abs(curr.value - aspectRatio);
      return prevDiff < currDiff ? prev : curr;
    });

    return closestOption.label;
  }

  changeAspectRatio(value: number): void {
    this.imageCropper.changeOption('aspectRatio', value);
  }

  saveCroppedImage(): void {
    if (this.file) {
      this.compressCroppedImage();
    }
  }

  closeCropper(): void {
    this.imageCropper.changeOption('isCropperVisible', false);
    this.croppedImageUrl = '';
    this.isLoading = true;
    this.cancelCropping.emit(true);
  }
}
