import { Injectable } from '@angular/core';
import { CanLoad, Route, Router, UrlSegment } from '@angular/router';
import { AuthService } from 'src/app/auth/services';
import { ROUTER_PATHS } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService
  ) {}

  canMatch(): boolean {
    const isLoggedIn = this.authService.isLoggedIn;

    if (isLoggedIn) {
      return true;
    }

    const { root, login } = ROUTER_PATHS.auth;
    this.router.navigate(['/', root, login]);

    return false;
  }
}
