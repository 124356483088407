import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { Constants, ROUTER_PATHS } from '../constants';
import { LocalStorageService, StorageItem } from '../services/local-storage.service';
import { Account } from 'src/app/auth/models/user.model';

@Injectable({
  providedIn: 'root'
})
export class RoleGuard {
  constructor(
    private readonly router: Router,
    private readonly localStorageService: LocalStorageService
  ) {}

  canActivate(activatedRouteSnapshot: ActivatedRouteSnapshot): boolean {
    const currentUser: Account = this.localStorageService.getItem(StorageItem.CurrentUser) as Account;
    const permittedRoles = activatedRouteSnapshot.data?.['roles'];

    if (permittedRoles.includes(currentUser.userRole) || permittedRoles.includes(Constants.roles.ALL)) {
      return true;
    }

    this.router.navigate([ROUTER_PATHS.dashboard.root]);
    return false;
  }
}
