export interface ImageCropperOptions {
  imageChangedEvent: any;
  outputHeight: number;
  isCropperVisible: boolean;
  name: string;
  outputWidth?: number;
  file?: File;
  aspectRatio: number;
  roundCropper: boolean;
  maintainAspectRatio: boolean;
  showAspectRatio: boolean;
  showAspectRatioSelection: boolean;
  containWithinAspectRatio: boolean;
}

export class ImageCropperModel {
  private aspectRatio = ASPECT_RATIOS.square;
  private containWithinAspectRatio = false;
  private file!: File;
  private imageChangedEvent!: any;
  private isCropperVisible = false;
  private maintainAspectRatio = true;
  private name!: string;
  private outputHeight = 800;
  private readonly outputWidth = this.outputHeight * this.aspectRatio;
  private roundCropper = false;
  private showAspectRatio = true;
  private showAspectRatioSelection = false;
  private _options!: ImageCropperOptions;

  constructor(imageCropperOptions: ImageCropperOptions) {
    this.options = { ...imageCropperOptions };
  }

  set options(imageCropperOptions: ImageCropperOptions) {
    this._options = { ...imageCropperOptions, outputWidth: this.outputWidth, file: this.file };
    this.processOptions();
  }

  get options(): ImageCropperOptions {
    return { ...this._options };
  }

  private processOptions(): void {
    this.aspectRatio = this._options.aspectRatio;
    this.imageChangedEvent = this._options.imageChangedEvent;
    this.isCropperVisible = this._options.isCropperVisible;
    this.name = this._options.name;
    this.outputHeight = this._options.outputHeight;
    this.roundCropper = this._options.roundCropper;
    this.maintainAspectRatio = this._options.maintainAspectRatio;
    this.showAspectRatio = this._options.showAspectRatio;
    this.showAspectRatioSelection = this._options.showAspectRatioSelection;
  }

  changeOption(key: keyof ImageCropperOptions, value: any): void {
    this.options = { ...this.options, [key]: value };
  }
}

export enum ASPECT_RATIOS {
  square = 1 / 1,
  rectangle = 4 / 3,
  wideRectangle = 16 / 9,
  none = 0
}

export enum AspectRatioOptions {
  Square = '1:1',
  FourThree = '4:3',
  SixteenNine = '16:9'
}

export const IMAGE_COMPRESSION = {
  quality: 0.7
};
