import { Directive, HostListener } from '@angular/core';

@Directive({
  selector: '[appDisableCopyPaste]'
})
export class DisableCopyPasteDirective {
  @HostListener('copy', ['$event'])
  onCopy(event: ClipboardEvent) {
    event.preventDefault();
  }

  @HostListener('paste', ['$event'])
  onPaste(event: ClipboardEvent) {
    event.preventDefault();
  }
}
