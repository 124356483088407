<ng-container>
  <ng-container [ngTemplateOutlet]="showPageLoader ? showSplashLoader : pageLayoutContent"></ng-container>
</ng-container>

<ng-template #pageLayoutContent>
  <ng-container *ngIf="appConfig.isTopNavEnabled; else sideBarTemplate">
    <ng-container [ngTemplateOutlet]="layoutTemplate"></ng-container>
  </ng-container>
</ng-template>

<ng-template #sideBarTemplate>
  <aside class="sidenav" *ngIf="isExpanded !== null">
    <ng-container [ngTemplateOutlet]="layoutTemplate"></ng-container>
  </aside>
</ng-template>

<ng-template #layoutTemplate>
  <ng-container [ngTemplateOutlet]="appConfig.isTopNavEnabled ? topNavLayout : sideNavLayout"></ng-container>
</ng-template>

<ng-template #mobileDeviceLayout>
  <div class="top-nav-wrapper">
    <app-mobile-header></app-mobile-header>
    <div
      [ngClass]="{ 'container top-header-content': true, 'top-header-content-without-footer': !appConfig.showFooter }">
      <router-outlet></router-outlet>
    </div>
    <app-footer *ngIf="appConfig.showFooter"></app-footer>
  </div>
</ng-template>

<ng-template #topNavLayout>
  <div class="top-nav-wrapper">
    <div class="sidenav-content-wrapper">
      <div>
        <app-header *ngIf="appConfig.isTopNavEnabled"></app-header>
      </div>
      <div
        [ngClass]="{
          'container top-header-content': true,
          'top-header-content-without-footer': !appConfig.showFooter
        }">
        <router-outlet></router-outlet>
      </div>
      <app-footer *ngIf="appConfig.showFooter"></app-footer>
    </div>
  </div>
</ng-template>

<ng-template #sideNavLayout>
  <div class="sidenav-wrapper">
    <div class="sidenav-content-wrapper">
      <div>
        <app-sidebar
          *ngIf="!appConfig.isTopNavEnabled"
          [isMobileDevice]="isMobileDevice"
          [isExpanded]="isExpanded"
          (setIsExpanded)="isExpanded = $event"
          [menuItems]="menuItems"></app-sidebar>
      </div>
      <div [ngClass]="isExpanded ? 'page-content-width-sidebar-expand' : 'page-content-width-sidebar-collapse'">
        <app-topnav [currentUser]="currentUser"></app-topnav>
        <div
          [ngClass]="{
            'container-fluid sidenav-content': true,
            'sidenav-content-without-footer': !appConfig.showFooter
          }">
          <router-outlet></router-outlet>
        </div>
        <app-footer *ngIf="appConfig.showFooter"></app-footer>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #showSplashLoader>
  <div class="splash-screen">
    <img [src]="constants.applicationLogoDarkUrl" alt="Logo" />
  </div>
</ng-template>
