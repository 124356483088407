import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { MenuConfig } from 'src/app/shared/constants';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent extends BaseComponent implements OnInit {
  currentActiveParentRoute!: string;
  menuItems = MenuConfig;

  constructor(
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
    this.setActiveRoute();
  }

  ngOnInit(): void {}

  setActiveRoute(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentActiveParentRoute = event.url.split('/')[1];
      } else if (event instanceof Scroll) {
        this.currentActiveParentRoute = event.routerEvent.url.split('/')[1];
      }
      this.cdr.detectChanges();
    });
  }
}
