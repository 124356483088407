import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Account } from 'src/app/auth/models/user.model';
import { LocalStorageService, StorageItem } from '../services/local-storage.service';

@Directive({
  selector: '[appHasPermission]'
})
export class HasPermissionDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainerRef: ViewContainerRef,
    private readonly localStorageService: LocalStorageService
  ) {}

  @Input()
  set appHasPermission(permittedPermissions: Array<string>) {
    const currentUser: Account = this.localStorageService.getItem(StorageItem.CurrentUser) as Account;
    if (permittedPermissions?.includes(currentUser.userRole)) {
      this.viewContainerRef.createEmbeddedView(this.templateRef);
      return;
    }
    this.viewContainerRef.clear();
  }
}
