import { finalize, MonoTypeOperatorFunction, Observable, shareReplay } from 'rxjs';

export class RxJsUtils {
  private static readonly inProgress = new Map<string, Observable<unknown>>();

  static shareDuplicate<T>(indentifier: string): MonoTypeOperatorFunction<T> {
    return (source$: Observable<T>): Observable<T> => {
      if (!this.inProgress.has(indentifier)) {
        this.inProgress.set(indentifier, source$.pipe(shareReplay(1)));
      }

      return (this.inProgress.get(indentifier) as Observable<T>).pipe(
        finalize(() => this.inProgress.delete(indentifier))
      );
    };
  }
}
