import { Directive, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';

@Directive({
  selector: '[appImageIconSrc]'
})
export class ImageIconDirective implements OnInit {
  @Input() hoverImgSrc!: string;
  @Input('appImageIconSrc') imgSrc!: string;

  constructor(
    private el: ElementRef<HTMLImageElement>,
    private renderer: Renderer2
  ) {}

  ngOnInit(): void {
    this.createImageElement();
    this.setImageSrc();
  }

  @HostListener('mouseover') onMouseOver() {
    this.renderer.setAttribute(this.imageElement, 'src', this.hoverImgSrc || this.imgSrc);
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.setImageSrc();
  }

  setImageSrc() {
    this.renderer.setAttribute(this.imageElement, 'src', this.imgSrc);
  }

  get imageElement(): HTMLImageElement {
    return this.el.nativeElement.getElementsByTagName('img')[0];
  }

  createImageElement() {
    const img = this.renderer.createElement('img');
    this.renderer.appendChild(this.el.nativeElement, img);
  }
}
