import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { ToggleDirections } from '../header/header.model';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent extends BaseComponent implements OnInit {
  @Input() currentActiveParentRoute!: string;
  @Input() isMobileDevice!: boolean;
  @Input() isExpanded!: boolean;
  @Input() menuItems: any;

  toggleDirections = ToggleDirections.LEFT;

  @Output() setIsExpanded = new EventEmitter<boolean>();

  constructor(
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef
  ) {
    super();
    this.setActiveRoute();
  }

  ngOnInit(): void {
    this.isExpanded = !this.isMobileDevice;
    this.setIsExpanded.emit(this.isExpanded);
  }

  setActiveRoute(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentActiveParentRoute = event.url.split('/')[1];
      } else if (event instanceof Scroll) {
        this.currentActiveParentRoute = event.routerEvent.url.split('/')[1];
      }
      this.cdr.detectChanges();
    });
  }

  handleSidebarToggle() {
    this.setIsExpanded.emit(!this.isExpanded);
    this.isExpanded = !this.isExpanded;
  }

  getIsParentActive(menuItem: any, currentActiveParentRoute: string): boolean {
    return menuItem?.moduleKeys.includes(currentActiveParentRoute);
  }
}
