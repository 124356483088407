import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class FooterComponent implements OnInit {
  currentYear = new Date().getFullYear();
  currentApplicationVersion!: string;
  buildDateTime!: string;

  ngOnInit(): void {
    this.setFooterParams();
  }

  setFooterParams() {
    this.currentApplicationVersion = `${environment.versions.version}-${environment.versions.revision}-${environment.versions.branch}`;
    this.buildDateTime = environment.versions.buildDateTime;
  }
}
