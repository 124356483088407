import { ChangeDetectorRef, Component } from '@angular/core';
import { Router, NavigationEnd, Scroll } from '@angular/router';
import { AuthService } from 'src/app/auth/services';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { MenuConfig } from 'src/app/shared/constants';

@Component({
  selector: 'app-mobile-header',
  templateUrl: './mobile-header.component.html',
  styleUrl: './mobile-header.component.scss'
})
export class MobileHeaderComponent extends BaseComponent {
  showSidebar = false;
  currentActiveParentRoute!: string;
  menuItems = MenuConfig;

  constructor(
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly authService: AuthService
  ) {
    super();
    this.setActiveRoute();
  }

  onSidebarClosed(): void {
    this.showSidebar = false;
  }

  setActiveRoute(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentActiveParentRoute = event.url.split('/')[1];
      } else if (event instanceof Scroll) {
        this.currentActiveParentRoute = event.routerEvent.url.split('/')[1];
      }
      this.cdr.detectChanges();
    });
  }
}
