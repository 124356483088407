import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PROGRESS_TYPES } from '../../constants';

@Component({
  selector: 'app-upload-progress',
  templateUrl: './upload-progress.component.html',
  styleUrls: ['./upload-progress.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UploadProgressComponent implements OnInit {
  @Input({ required: true }) uploadPercentage!: number;
  @Input({ required: true }) progressType!: PROGRESS_TYPES;

  progressTypes = PROGRESS_TYPES;

  constructor() {}

  ngOnInit(): void {}
}
