import { Component, Input, OnInit } from '@angular/core';
import { EChartsOption } from 'echarts';
import { CHART_THEMES, ThemeService } from '../../services/theme.service';

@Component({
  selector: 'app-chart-viewer',
  templateUrl: './chart-viewer.component.html'
})
export class ChartViewerComponent implements OnInit {
  @Input() chartOption!: EChartsOption;

  @Input() pairWithGlobalTheme = true;
  @Input() chartTheme!: CHART_THEMES;
  protected _chartTheme!: CHART_THEMES;

  constructor(private readonly themeService: ThemeService) {}

  ngOnInit(): void {
    this.setTheme();
    this.setCustomChartOptions();
  }

  setTheme(): void {
    if (this.pairWithGlobalTheme) {
      this.themeService.selectedChartTheme$.subscribe((chartTheme: CHART_THEMES) => {
        this._chartTheme = chartTheme;
      });
    }
    if (this.chartTheme) {
      this._chartTheme = this.chartTheme;
    }
  }

  setCustomChartOptions(): void {
    this.chartOption = {
      ...this.chartOption,
      backgroundColor: 'transparent'
    };
  }
}
