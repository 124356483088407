import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {
  private readonly SECRET_KEY = environment.encryptionService.secretKey;
  private readonly SECRET_IV = environment.encryptionService.secretIV;

  private readonly ENCRYPTION_KEY = CryptoJS.enc.Utf8.parse(this.SECRET_KEY);
  private readonly CIPHER_IV = CryptoJS.enc.Utf8.parse(this.SECRET_IV);
  private readonly CIPHER_MODE = CryptoJS.mode.CBC;
  private readonly CIPHER_PADDING = CryptoJS.pad.Pkcs7;
  private readonly CIPHER_KEY_SIZE = 16;

  private readonly STD_DECRYPTION_ENCODER = CryptoJS.enc.Utf8;

  constructor() {}

  private get CIPHER_OPTIONS() {
    return {
      iv: this.CIPHER_IV,
      mode: this.CIPHER_MODE,
      padding: this.CIPHER_PADDING,
      keySize: this.CIPHER_KEY_SIZE
    };
  }

  encrypt(plainText: string): string {
    if (environment.encryptionService.enable && plainText) {
      return CryptoJS.AES.encrypt(plainText, this.ENCRYPTION_KEY, this.CIPHER_OPTIONS).toString();
    }

    return plainText;
  }

  decrypt(encryptedText: string): string {
    if (environment.encryptionService.enable && encryptedText) {
      return CryptoJS.AES.decrypt(encryptedText, this.ENCRYPTION_KEY, this.CIPHER_OPTIONS).toString(
        this.STD_DECRYPTION_ENCODER
      );
    }
    return encryptedText;
  }
}
