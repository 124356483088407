import {
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faArrowLeft,
  faBars,
  faCheckCircle,
  faCity,
  faColumns,
  faEnvelope,
  faEnvelopeSquare,
  faEye,
  faFileAlt,
  faPencil,
  faPhoneAlt,
  faSignOutAlt,
  faSignal,
  faSort,
  faSortDown,
  faSortUp,
  faSpinner,
  faTimes,
  faTrash,
  faUser
} from '@fortawesome/free-solid-svg-icons';

export const faIcons = {
  faSpinner,
  faCity,
  faColumns,
  faSignal,
  faFileAlt,
  faEnvelope,
  faPhoneAlt,
  faUser,
  faArrowLeft,
  faCheckCircle,
  faSignOutAlt,
  faEnvelopeSquare,
  faSort,
  faSortDown,
  faSortUp,
  faBars,
  faAngleDoubleLeft,
  faAngleDoubleRight,
  faTimes,
  faPencil,
  faEye,
  faTrash
};
