import { MbscModule } from '@mobiscroll/angular';
import { FormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, ErrorHandler, NgModule, isDevMode } from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import * as Sentry from '@sentry/angular-ivy';
import { ConfirmationService, MessageService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { MessagesModule } from 'primeng/messages';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { SharedComponentsModule } from './shared/components/shared-components.module';
import { JwtInterceptor, ServerErrorInterceptor } from './shared/interceptors';
import { RefreshTokenService, refreshTokenInitializer } from './shared/services';

import { DatePipe } from '@angular/common';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { initializeApp } from 'firebase/app';
import { DialogModule } from 'primeng/dialog';
import { MessageModule } from 'primeng/message';
import { environment } from 'src/environments/environment';

import { SocialLoginModule, SocialAuthServiceConfig } from '@abacritt/angularx-social-login';
import { GoogleLoginProvider } from '@abacritt/angularx-social-login';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
initializeApp(environment.firebase);

@NgModule({
  declarations: [AppComponent],
  imports: [
    MbscModule,
    FormsModule,
    BrowserModule,
    AppRoutingModule,
    SharedComponentsModule,
    LayoutModule,
    HttpClientModule,
    BrowserAnimationsModule,
    DialogModule,
    ConfirmDialogModule,
    MessagesModule,
    MessageModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000'
    }),
    AngularFireMessagingModule,
    AngularFireModule.initializeApp(environment.firebase),
    SocialLoginModule,
    CKEditorModule
  ],
  providers: [
    DatePipe,
    MessageService,
    ConfirmationService,
    {
      provide: APP_INITIALIZER,
      useFactory: refreshTokenInitializer,
      deps: [RefreshTokenService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ServerErrorInterceptor,
      multi: true
    },
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
        logErrors: true
      })
    },
    {
      provide: Sentry.TraceService,
      deps: [Router]
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '544014383555-rqb2n2g60m12lpece8nktlpecrb0r30h.apps.googleusercontent.com',
              {
                scopes: 'openid profile email',
                oneTapEnabled: true,
                prompt: 'none'
              }
            )
          }
        ],
        onError: (err) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
