import { Injectable } from '@angular/core';
import { API_URL } from 'src/app/shared/constants/api-url.constants';
import { BaseCrudService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class InstrumentsService extends BaseCrudService {
  getBaseAPIPath() {
    return `${API_URL.services}/${API_URL.app}/${API_URL.instruments.root}`;
  }
}
